/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.141.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { AufgabeNeuRest } from '../models';
// @ts-ignore
import { AufgabeRest } from '../models';
// @ts-ignore
import { AufgabeSucheRest } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
/**
 * AufgabeResourceApi - axios parameter creator
 * @export
 */
export const AufgabeResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {AufgabeNeuRest} aufgabeNeuRest 
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAufgabe: async (aufgabeNeuRest: AufgabeNeuRest, planID: string, vtsDurchgang: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'aufgabeNeuRest' is not null or undefined
            assertParamExists('createAufgabe', 'aufgabeNeuRest', aufgabeNeuRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('createAufgabe', 'planID', planID)
            // verify required parameter 'vtsDurchgang' is not null or undefined
            assertParamExists('createAufgabe', 'vtsDurchgang', vtsDurchgang)
            const localVarPath = `/verfahren/{planID}/{vtsDurchgang}/aufgaben/neu`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"vtsDurchgang"}}`, encodeURIComponent(String(vtsDurchgang)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aufgabeNeuRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} aufgabeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAufgabe: async (planID: string, aufgabeID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteAufgabe', 'planID', planID)
            // verify required parameter 'aufgabeID' is not null or undefined
            assertParamExists('deleteAufgabe', 'aufgabeID', aufgabeID)
            const localVarPath = `/verfahren/{planID}/aufgaben/{aufgabeID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"aufgabeID"}}`, encodeURIComponent(String(aufgabeID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @param {string} planID 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAufgaben: async (planID: string, name?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getAllAufgaben', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/aufgaben`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neuerDurchgangAufgabe: async (planID: string, aufgabenID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('neuerDurchgangAufgabe', 'planID', planID)
            // verify required parameter 'aufgabenID' is not null or undefined
            assertParamExists('neuerDurchgangAufgabe', 'aufgabenID', aufgabenID)
            const localVarPath = `/verfahren/{planID}/aufgaben/{aufgabenID}/neuenDurchgangAnlegen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"aufgabenID"}}`, encodeURIComponent(String(aufgabenID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAufgabeErledigt: async (planID: string, vtsDurchgang: number, aufgabenID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setAufgabeErledigt', 'planID', planID)
            // verify required parameter 'vtsDurchgang' is not null or undefined
            assertParamExists('setAufgabeErledigt', 'vtsDurchgang', vtsDurchgang)
            // verify required parameter 'aufgabenID' is not null or undefined
            assertParamExists('setAufgabeErledigt', 'aufgabenID', aufgabenID)
            const localVarPath = `/verfahren/{planID}/{vtsDurchgang}/aufgaben/{aufgabenID}/erledigt`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"vtsDurchgang"}}`, encodeURIComponent(String(vtsDurchgang)))
                .replace(`{${"aufgabenID"}}`, encodeURIComponent(String(aufgabenID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAufgabeUnerledigt: async (planID: string, vtsDurchgang: number, aufgabenID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setAufgabeUnerledigt', 'planID', planID)
            // verify required parameter 'vtsDurchgang' is not null or undefined
            assertParamExists('setAufgabeUnerledigt', 'vtsDurchgang', vtsDurchgang)
            // verify required parameter 'aufgabenID' is not null or undefined
            assertParamExists('setAufgabeUnerledigt', 'aufgabenID', aufgabenID)
            const localVarPath = `/verfahren/{planID}/{vtsDurchgang}/aufgaben/{aufgabenID}/unerledigt`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"vtsDurchgang"}}`, encodeURIComponent(String(vtsDurchgang)))
                .replace(`{${"aufgabenID"}}`, encodeURIComponent(String(aufgabenID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AufgabeResourceApi - functional programming interface
 * @export
 */
export const AufgabeResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AufgabeResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {AufgabeNeuRest} aufgabeNeuRest 
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAufgabe(aufgabeNeuRest: AufgabeNeuRest, planID: string, vtsDurchgang: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAufgabe(aufgabeNeuRest, planID, vtsDurchgang, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AufgabeResourceApi.createAufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} aufgabeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAufgabe(planID: string, aufgabeID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAufgabe(planID, aufgabeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AufgabeResourceApi.deleteAufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @param {string} planID 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAufgaben(planID: string, name?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AufgabeSucheRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAufgaben(planID, name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AufgabeResourceApi.getAllAufgaben']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async neuerDurchgangAufgabe(planID: string, aufgabenID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.neuerDurchgangAufgabe(planID, aufgabenID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AufgabeResourceApi.neuerDurchgangAufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAufgabeErledigt(planID: string, vtsDurchgang: number, aufgabenID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAufgabeErledigt(planID, vtsDurchgang, aufgabenID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AufgabeResourceApi.setAufgabeErledigt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setAufgabeUnerledigt(planID: string, vtsDurchgang: number, aufgabenID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setAufgabeUnerledigt(planID, vtsDurchgang, aufgabenID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AufgabeResourceApi.setAufgabeUnerledigt']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AufgabeResourceApi - factory interface
 * @export
 */
export const AufgabeResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AufgabeResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {AufgabeNeuRest} aufgabeNeuRest 
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAufgabe(aufgabeNeuRest: AufgabeNeuRest, planID: string, vtsDurchgang: number, options?: any): AxiosPromise<AufgabeRest> {
            return localVarFp.createAufgabe(aufgabeNeuRest, planID, vtsDurchgang, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} aufgabeID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAufgabe(planID: string, aufgabeID: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAufgabe(planID, aufgabeID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @param {string} planID 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAufgaben(planID: string, name?: string, options?: any): AxiosPromise<Array<AufgabeSucheRest>> {
            return localVarFp.getAllAufgaben(planID, name, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        neuerDurchgangAufgabe(planID: string, aufgabenID: string, options?: any): AxiosPromise<AufgabeRest> {
            return localVarFp.neuerDurchgangAufgabe(planID, aufgabenID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAufgabeErledigt(planID: string, vtsDurchgang: number, aufgabenID: string, options?: any): AxiosPromise<AufgabeRest> {
            return localVarFp.setAufgabeErledigt(planID, vtsDurchgang, aufgabenID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {string} planID 
         * @param {number} vtsDurchgang 
         * @param {string} aufgabenID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAufgabeUnerledigt(planID: string, vtsDurchgang: number, aufgabenID: string, options?: any): AxiosPromise<AufgabeRest> {
            return localVarFp.setAufgabeUnerledigt(planID, vtsDurchgang, aufgabenID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AufgabeResourceApi - object-oriented interface
 * @export
 * @class AufgabeResourceApi
 * @extends {BaseAPI}
 */
export class AufgabeResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {AufgabeNeuRest} aufgabeNeuRest 
     * @param {string} planID 
     * @param {number} vtsDurchgang 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AufgabeResourceApi
     */
    public createAufgabe(aufgabeNeuRest: AufgabeNeuRest, planID: string, vtsDurchgang: number, options?: RawAxiosRequestConfig) {
        return AufgabeResourceApiFp(this.configuration).createAufgabe(aufgabeNeuRest, planID, vtsDurchgang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} aufgabeID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AufgabeResourceApi
     */
    public deleteAufgabe(planID: string, aufgabeID: string, options?: RawAxiosRequestConfig) {
        return AufgabeResourceApiFp(this.configuration).deleteAufgabe(planID, aufgabeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
     * @param {string} planID 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AufgabeResourceApi
     */
    public getAllAufgaben(planID: string, name?: string, options?: RawAxiosRequestConfig) {
        return AufgabeResourceApiFp(this.configuration).getAllAufgaben(planID, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} aufgabenID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AufgabeResourceApi
     */
    public neuerDurchgangAufgabe(planID: string, aufgabenID: string, options?: RawAxiosRequestConfig) {
        return AufgabeResourceApiFp(this.configuration).neuerDurchgangAufgabe(planID, aufgabenID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {number} vtsDurchgang 
     * @param {string} aufgabenID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AufgabeResourceApi
     */
    public setAufgabeErledigt(planID: string, vtsDurchgang: number, aufgabenID: string, options?: RawAxiosRequestConfig) {
        return AufgabeResourceApiFp(this.configuration).setAufgabeErledigt(planID, vtsDurchgang, aufgabenID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {string} planID 
     * @param {number} vtsDurchgang 
     * @param {string} aufgabenID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AufgabeResourceApi
     */
    public setAufgabeUnerledigt(planID: string, vtsDurchgang: number, aufgabenID: string, options?: RawAxiosRequestConfig) {
        return AufgabeResourceApiFp(this.configuration).setAufgabeUnerledigt(planID, vtsDurchgang, aufgabenID, options).then((request) => request(this.axios, this.basePath));
    }
}

