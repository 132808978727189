/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.141.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { NutzerEditRest } from '../models';
// @ts-ignore
import { NutzerRest } from '../models';
// @ts-ignore
import { NutzerSucheRest } from '../models';
// @ts-ignore
import { NutzerUnterAufgabeNeuRest } from '../models';
// @ts-ignore
import { NutzerUnterAufgabeRest } from '../models';
// @ts-ignore
import { NutzerVerfahrenListRest } from '../models';
// @ts-ignore
import { NutzerVerfahrenRest } from '../models';
/**
 * NutzerResourceApi - axios parameter creator
 * @export
 */
export const NutzerResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNutzerVerfahren: async (nutzerVerfahrenRest: NutzerVerfahrenRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutzerVerfahrenRest' is not null or undefined
            assertParamExists('addNutzerVerfahren', 'nutzerVerfahrenRest', nutzerVerfahrenRest)
            const localVarPath = `/nutzer/verfahren/neu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutzerVerfahrenRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOneNutzerVerfahren: async (nutzerVerfahrenRest: NutzerVerfahrenRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutzerVerfahrenRest' is not null or undefined
            assertParamExists('addOneNutzerVerfahren', 'nutzerVerfahrenRest', nutzerVerfahrenRest)
            const localVarPath = `/nutzer/verfahren/abonnieren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutzerVerfahrenRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {NutzerUnterAufgabeNeuRest} nutzerUnterAufgabeNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNutzerUnteraufgabe: async (nutzerUnterAufgabeNeuRest: NutzerUnterAufgabeNeuRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutzerUnterAufgabeNeuRest' is not null or undefined
            assertParamExists('createNutzerUnteraufgabe', 'nutzerUnterAufgabeNeuRest', nutzerUnterAufgabeNeuRest)
            const localVarPath = `/nutzer/unteraufgaben`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutzerUnterAufgabeNeuRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutzer: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutzerVerfahren: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nutzer/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOneNutzerVerfahren: async (nutzerVerfahrenRest: NutzerVerfahrenRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutzerVerfahrenRest' is not null or undefined
            assertParamExists('removeOneNutzerVerfahren', 'nutzerVerfahrenRest', nutzerVerfahrenRest)
            const localVarPath = `/nutzer/verfahren/deabonnieren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutzerVerfahrenRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Führt die Suche nach einem Nutzer aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Führt die Suche nach einem Nutzer aus.
         * @param {string} email Email des Nutzers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sucheNutzer: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sucheNutzer', 'email', email)
            const localVarPath = `/nutzer/suche`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {NutzerEditRest} nutzerEditRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutzerSettings: async (nutzerEditRest: NutzerEditRest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutzerEditRest' is not null or undefined
            assertParamExists('updateNutzerSettings', 'nutzerEditRest', nutzerEditRest)
            const localVarPath = `/nutzer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutzerEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {Array<NutzerVerfahrenRest>} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutzerVerfahren: async (nutzerVerfahrenRest: Array<NutzerVerfahrenRest>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nutzerVerfahrenRest' is not null or undefined
            assertParamExists('updateNutzerVerfahren', 'nutzerVerfahrenRest', nutzerVerfahrenRest)
            const localVarPath = `/nutzer/verfahren`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nutzerVerfahrenRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NutzerResourceApi - functional programming interface
 * @export
 */
export const NutzerResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NutzerResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NutzerVerfahrenRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addNutzerVerfahren(nutzerVerfahrenRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.addNutzerVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addOneNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutzerVerfahrenListRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addOneNutzerVerfahren(nutzerVerfahrenRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.addOneNutzerVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {NutzerUnterAufgabeNeuRest} nutzerUnterAufgabeNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNutzerUnteraufgabe(nutzerUnterAufgabeNeuRest: NutzerUnterAufgabeNeuRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutzerUnterAufgabeRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNutzerUnteraufgabe(nutzerUnterAufgabeNeuRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.createNutzerUnteraufgabe']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutzer(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutzerRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutzer(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.getNutzer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNutzerVerfahren(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NutzerVerfahrenRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNutzerVerfahren(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.getNutzerVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeOneNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutzerVerfahrenListRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeOneNutzerVerfahren(nutzerVerfahrenRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.removeOneNutzerVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Führt die Suche nach einem Nutzer aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Führt die Suche nach einem Nutzer aus.
         * @param {string} email Email des Nutzers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sucheNutzer(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutzerSucheRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sucheNutzer(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.sucheNutzer']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {NutzerEditRest} nutzerEditRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNutzerSettings(nutzerEditRest: NutzerEditRest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NutzerRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNutzerSettings(nutzerEditRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.updateNutzerSettings']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {Array<NutzerVerfahrenRest>} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNutzerVerfahren(nutzerVerfahrenRest: Array<NutzerVerfahrenRest>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NutzerVerfahrenRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNutzerVerfahren(nutzerVerfahrenRest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NutzerResourceApi.updateNutzerVerfahren']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NutzerResourceApi - factory interface
 * @export
 */
export const NutzerResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NutzerResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: any): AxiosPromise<Array<NutzerVerfahrenRest>> {
            return localVarFp.addNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOneNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: any): AxiosPromise<NutzerVerfahrenListRest> {
            return localVarFp.addOneNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @param {NutzerUnterAufgabeNeuRest} nutzerUnterAufgabeNeuRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNutzerUnteraufgabe(nutzerUnterAufgabeNeuRest: NutzerUnterAufgabeNeuRest, options?: any): AxiosPromise<NutzerUnterAufgabeRest> {
            return localVarFp.createNutzerUnteraufgabe(nutzerUnterAufgabeNeuRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutzer(options?: any): AxiosPromise<NutzerRest> {
            return localVarFp.getNutzer(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNutzerVerfahren(options?: any): AxiosPromise<Array<NutzerVerfahrenRest>> {
            return localVarFp.getNutzerVerfahren(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeOneNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: any): AxiosPromise<NutzerVerfahrenListRest> {
            return localVarFp.removeOneNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Führt die Suche nach einem Nutzer aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Führt die Suche nach einem Nutzer aus.
         * @param {string} email Email des Nutzers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sucheNutzer(email: string, options?: any): AxiosPromise<NutzerSucheRest> {
            return localVarFp.sucheNutzer(email, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {NutzerEditRest} nutzerEditRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutzerSettings(nutzerEditRest: NutzerEditRest, options?: any): AxiosPromise<NutzerRest> {
            return localVarFp.updateNutzerSettings(nutzerEditRest, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
         * @param {Array<NutzerVerfahrenRest>} nutzerVerfahrenRest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNutzerVerfahren(nutzerVerfahrenRest: Array<NutzerVerfahrenRest>, options?: any): AxiosPromise<Array<NutzerVerfahrenRest>> {
            return localVarFp.updateNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NutzerResourceApi - object-oriented interface
 * @export
 * @class NutzerResourceApi
 * @extends {BaseAPI}
 */
export class NutzerResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
     * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public addNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).addNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
     * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public addOneNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).addOneNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @param {NutzerUnterAufgabeNeuRest} nutzerUnterAufgabeNeuRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public createNutzerUnteraufgabe(nutzerUnterAufgabeNeuRest: NutzerUnterAufgabeNeuRest, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).createNutzerUnteraufgabe(nutzerUnterAufgabeNeuRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> isAuthenticated()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public getNutzer(options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).getNutzer(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> isAuthenticated()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public getNutzerVerfahren(options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).getNutzerVerfahren(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
     * @param {NutzerVerfahrenRest} nutzerVerfahrenRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public removeOneNutzerVerfahren(nutzerVerfahrenRest: NutzerVerfahrenRest, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).removeOneNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Führt die Suche nach einem Nutzer aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Führt die Suche nach einem Nutzer aus.
     * @param {string} email Email des Nutzers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public sucheNutzer(email: string, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).sucheNutzer(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> isAuthenticated()
     * @param {NutzerEditRest} nutzerEditRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public updateNutzerSettings(nutzerEditRest: NutzerEditRest, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).updateNutzerSettings(nutzerEditRest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_ABONNIEREN or ADMIN
     * @param {Array<NutzerVerfahrenRest>} nutzerVerfahrenRest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NutzerResourceApi
     */
    public updateNutzerVerfahren(nutzerVerfahrenRest: Array<NutzerVerfahrenRest>, options?: RawAxiosRequestConfig) {
        return NutzerResourceApiFp(this.configuration).updateNutzerVerfahren(nutzerVerfahrenRest, options).then((request) => request(this.axios, this.basePath));
    }
}

