<script>
  import { Modal } from "bootstrap";
  import { DpsButton } from "diplanung-style";

  import { currentPlanID } from "@/mixins/current-plan-id.js";

  export default {
    name: "AppModal",
    components: { DpsButton },
    mixins: [currentPlanID],
    provide() {
      return {
        planID: this.planID,
      };
    },
    props: {
      hasCloseButton: Boolean,
      title: {
        type: String,
        required: false,
        default: "",
      },
      dismissBtn: {
        type: Object,
        required: false,
        default() {
          return {
            label: "",
            title: "",
            disabled: false,
          };
        },
      },
      confirmBtn: {
        type: Object,
        required: false,
        default() {
          return {
            label: "",
            title: "",
            disabled: false,
          };
        },
      },
      visible: {
        type: Boolean,
        required: false,
        default: false,
      },
      showModalFooter: {
        type: Boolean,
        required: false,
        default: false,
      },
      showModalHeader: {
        type: Boolean,
        required: false,
        default: false,
      },
      size: {
        type: String,
        required: false,
        default: undefined,
        validator(value) {
          return ["sm", "md", "lg", "xl", "xxl", "full-width"].includes(value);
        },
      },
      // modal will not be closed by clicking outside or using the esc key
      asStatic: {
        type: Boolean,
        required: false,
        default: false,
      },
      scrollable: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ["close-modal", "confirm-modal"],
    data() {
      return {
        modalInstance: null,
        showModal: false, // will be used by extended modals which are independent of visible prop
        refName: "modal",
      };
    },
    computed: {
      modalSizeCssClass() {
        return this.size ? `modal-${this.size}` : "";
      },
      isModalHeaderVisible() {
        return this.title.length > 0 || this.showModalHeader;
      },
    },
    watch: {
      visible(newValue) {
        if (newValue) {
          this.modalInstance.show();
        } else {
          this.modalInstance.hide();
        }
      },
    },
    mounted: function () {
      const modal = this.$refs[this.refName];

      // return if no modal element exists
      if (!modal) return;

      this.modalInstance = new Modal(modal, {
        backdrop: this.asStatic ? "static" : true,
        keyboard: !this.asStatic,
      });
      modal.addEventListener("hide.bs.modal", () => this.handleHide());

      if (this.visible || this.showModal) this.modalInstance.show();
    },
    beforeUnmount() {
      if (!this.modalInstance) return;

      const modal = this.$refs[this.refName];

      modal.removeEventListener("hide.bs.modal", () => this.handleHide());

      this.modalInstance.hide();
    },
    methods: {
      /**
       * function to handle the hide event of bootstrap modal
       * @returns {void}
       */
      handleHide() {
        this.$emit("close-modal");
      },

      /**
       * function to handle the case of confirm the modal
       * @returns {void}
       */
      handleConfirm() {
        this.$emit("confirm-modal");
      },
    },
  };
</script>

<template>
  <div :ref="refName" class="dpc-modal modal dpc-fade" tabindex="-1">
    <div
      class="modal-dialog modal-dialog-centered"
      :class="[modalSizeCssClass, { 'modal-dialog-scrollable': scrollable }]"
    >
      <div class="modal-content">
        <!-- header: if title was committed separately -->
        <div v-if="isModalHeaderVisible" class="modal-header">
          <slot name="header">
            <div v-if="title" class="modal-title">{{ title }}</div>
            <slot name="header-icon" />
          </slot>
          <button
            v-if="hasCloseButton"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Schließen"
            title="Modal schließen"
          ></button>
        </div>

        <!-- body -->
        <div class="modal-body" :class="{ 'modal-body--body-only': !title }">
          <!-- header: if title is part of the content, show the close button as part of the content as well -->
          <button
            v-if="hasCloseButton && !isModalHeaderVisible"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Schließen"
          ></button>

          <!-- content -->
          <slot />
        </div>

        <!-- footer: show buttons if they are not part of the content -->

        <div v-if="showModalFooter" class="modal-footer">
          <slot name="footer">
            <DpsButton
              v-if="dismissBtn.label"
              variant="secondary"
              size="sm"
              :disabled="dismissBtn?.disabled"
              :title="dismissBtn?.title ? dismissBtn.title : dismissBtn.label"
              data-bs-dismiss="modal"
            >
              {{ dismissBtn.label }}
            </DpsButton>
            <DpsButton
              v-if="confirmBtn.label"
              size="sm"
              :disabled="confirmBtn?.disabled"
              :title="confirmBtn?.title ? confirmBtn.title : confirmBtn.label"
              @click="handleConfirm"
              @keydown.space="handleConfirm"
            >
              {{ confirmBtn.label }}
            </DpsButton>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @use "diplanung-style/src/scss/variables/heading" as heading-variables;
  @use "diplanung-style/src/scss/variables/transitions" as transitions-variables;
  @use "diplanung-style/src/scss/mixins/heading" as heading-mixins;
  @import "@/styles/imports";

  .modal {
    --bs-modal-border-color: #40648a;
    --coc-modal-blue: #003063;

    .btn-close {
      opacity: 1;
    }

    .modal-dialog {
      --coc-modal-width: 90vw;

      max-width: var(--coc-modal-width);

      &.modal-full-width {
        --coc-modal-width: calc(100vw - 64px);
      }

      @include media-breakpoint-up(lg) {
        --coc-modal-width: 496px;

        &.modal-sm {
          --coc-modal-width: 300px;
        }

        &.modal-md {
          --coc-modal-width: 590px;
        }

        &.modal-lg {
          --coc-modal-width: 790px;
        }

        &.modal-xl {
          --coc-modal-width: 960px;
        }

        &.modal-xxl {
          --coc-modal-width: 1140px;
        }
      }

      @include media-breakpoint-up(xxl) {
        &.modal-full-width {
          --coc-modal-width: min(calc(100vw - 96px), 1905px);
        }
      }

      .modal-title {
        @include heading-mixins.heading(heading-variables.$heading-3);
      }
    }

    .modal-title {
      @include heading-mixins.heading(heading-variables.$heading-5);

      display: block;
      font-family: heading-variables.$heading-font-family, sans-serif;
      margin: 0;
    }

    .modal-content {
      border-radius: 0;
      border: none;

      // position the close button if title is part of the content
      .btn-close {
        position: absolute;
        right: 20px;
        top: 24px;
      }
    }

    .modal-header {
      padding: 20px 25px 2px;
    }

    .modal-hl {
      margin-top: 50px;
      text-align: center;
    }

    .modal-body {
      padding: 16px 25px;

      // reset xl/xxl row grid style to bootstrap default
      @include media-breakpoint-up(xl) {
        .row {
          --bs-gutter-x: 1.5rem;

          margin-right: calc(-0.5 * var(--bs-gutter-x));
          margin-left: calc(-0.5 * var(--bs-gutter-x));
        }
      }

      @include media-breakpoint-up(xxl) {
        .row {
          margin-right: calc(-0.5 * var(--bs-gutter-x));
          margin-left: calc(-0.5 * var(--bs-gutter-x));
        }
      }

      &:last-child {
        padding-bottom: 30px;
      }

      // if title section is contained in the modal body
      &--body-only {
        padding: 0;

        .btn-close {
          position: absolute;
          top: 30px;
          right: 25px;
          left: auto;
          z-index: 1;
          padding: 0;
        }

        .modal-content__header {
          padding: 24px 46px 16px 25px;
          background-color: var(--dps-color-background);
        }

        .modal-content__body {
          padding: 8px 25px 32px;
        }
      }
    }

    .modal-footer {
      padding: 24px 25px 30px;
    }

    .modal-body,
    .modal-footer {
      .dps-button {
        margin-bottom: 0;
        font-size: 14px;
      }
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      border: none;
    }

    .modal-info-text {
      margin: 0;
      padding: 0;
      word-wrap: break-word;
    }

    // ---

    &.modal-info {
      justify-content: center;

      .modal-header {
        flex-direction: column;

        .modal-hl {
          @include heading-mixins.heading(heading-variables.$heading-4);
        }

        .dps-icon {
          font-size: 30px;
          line-height: 1.15em;
          color: var(--dps-color-white);
          border-radius: 100%;
          position: absolute;
          top: -2.5rem;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;

          &--warning {
            background-color: var(--dps-color-warning);
            color: var(--dps-color-black);
          }

          &.error,
          &.delete {
            background-color: var(--dps-color-error);
            color: var(--dps-color-white);
          }

          &.success {
            background-color: var(--dps-color-success);
          }

          &.info {
            background-color: var(--dps-color-primary);
          }

          &.normal {
            background-color: var(--coc-modal-blue);
          }
        }
      }

      .modal-body,
      .modal-footer {
        justify-content: center;
        text-align: center;
      }

      .modal-hl {
        font-weight: 600;

        &.error {
          font-weight: 700;
        }
      }
    }

    &.dpc-fade {
      .modal-dialog {
        transition: transform transitions-variables.$transition-duration-short
          transitions-variables.$transition-timing-function;
        transform: scale(0.98);
      }
    }

    &.show {
      .modal-dialog {
        transform: scale(1);
      }
    }

    &.modal--warning {
      .modal-content {
        --bs-modal-border-color: var(--dps-color-warning);
      }
    }

    &.modal--error,
    &.modal--delete {
      .modal-content {
        --bs-modal-border-color: var(--dps-color-error);
      }
    }

    &.modal--success {
      .modal-content {
        --bs-modal-border-color: var(--dps-color-success);
      }
    }

    &.modal--info {
      .modal-content {
        --bs-modal-border-color: var(--mx-color-blue-queen);
      }
    }
  }
</style>
