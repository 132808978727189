import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.ts";
import { useFundamentalStore } from "@/stores/fundamental.ts";

/**
 * add a user proceeding
 * @param {NutzerVerfahrenRest} planID
 * @returns { AxiosPromise<NutzerVerfahrenListRest>}
 */
export function addUserProceedings(planID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .nutzerResourceApiFactory()
      .addOneNutzerVerfahren(planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * remove a user proceeding
 * * @param {NutzerVerfahrenRest} planID
 * @returns { AxiosPromise<NutzerVerfahrenListRest>}
 */
export function removeUserProceedings(planID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .nutzerResourceApiFactory()
      .removeOneNutzerVerfahren(planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
/**
 * updates a user proceeding
 * @param {Array<NutzerVerfahrenRest>} userSubscriptionIds
 * @returns { AxiosPromise<NutzerVerfahrenRest[]>}
 */
export function updateUserProceedings(userSubscriptionIds) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .nutzerResourceApiFactory()
      .updateNutzerVerfahren(userSubscriptionIds)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * update user settings
 * @param {Object} cockpitSettings
 */
export function updateUserSettings(cockpitSettings) {
  const cockpitSettingsRequestBody = JSON.stringify(cockpitSettings);

  return new Promise((resolve, reject) => {
    openAPIFactory
      .nutzerResourceApiFactory()
      .updateNutzerSettings({ cockpitSettings: cockpitSettingsRequestBody })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Returns the settings for metadaten export
 * @param {string} planID
 */
export function getVerfahrenEinstellungenByID(planID) {
  const appStore = useAppStore();

  return new Promise((resolve, reject) => {
    appStore.showPageLoadingIndicator({
      id: "loadVerfahrenEinstellungen",
      text: "Einen Moment bitte, die Einstellungen werden geladen.",
    });
    openAPIFactory
      .VerfahrenEinstellungenRessourceApiFactory()
      .getEinstellungen(planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        appStore.showErrorModal({
          response: error,
          customErrorMessage: "Laden der CSW-ID fehlgeschlagen!",
        });
        reject(error);
      })
      .finally(() => {
        appStore.hidePageLoadingIndicator("loadVerfahrenEinstellungen");
      });
  });
}
/**
 * Sets the settings for metadaten export
 * @param {string} planID
 * @param {VerfahrenEinstellungenRest} verfahrenEinstellungen - Contains the metadatenID and beteiligungsID
 */
export function setVerfahrenEinstellungenByID(planID, verfahrenEinstellungen) {
  const appStore = useAppStore();

  return new Promise((resolve, reject) => {
    appStore.showPageLoadingIndicator({
      id: "setVerfahrenEinstellungen",
      text: "Einen Moment bitte, die Einstellungen werden gespeichert.",
    });
    openAPIFactory
      .VerfahrenEinstellungenRessourceApiFactory()
      .setEinstellungen(verfahrenEinstellungen, planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        appStore.showErrorModal({
          response: error,
          customErrorMessage: "Speichern der CSW-ID fehlgeschlagen!",
        });
        reject(error);
      })
      .finally(() => {
        appStore.hidePageLoadingIndicator("setVerfahrenEinstellungen");
      });
  });
}

/**
 * updates a Planname
 * @param {string} planID
 * @param {VerfahrenEditRest} proceedingData - data with new planname
 * @returns {AxiosPromise<VerfahrenDetailRest>}
 */
export function updatePlanname(planID, proceedingData) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .verfahrenResourceApiFactory()
      .updateVerfahrenPlanname(proceedingData, planID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Returns the proceeding configuration object.
 * @param {string} proceedingID
 * @returns {Promise<VerfahrensKonfigurationRest>}
 */
export function getProceedingConfiguration(proceedingID) {
  return new Promise((resolve, reject) => {
    openAPIFactory
      .verfahrenResourceApiFactory()
      .getVerfahrenskonfiguration(proceedingID)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Restores a proceeding by its ID.
 *
 * This function sends a request to restore a proceeding identified by its ID.
 * @param {string} proceedingID
 * @returns {Promise<VerfahrensKonfigurationRest>}
 */
export function restoreProceeding(proceedingID) {
  const appStore = useAppStore();

  return new Promise((resolve, reject) => {
    openAPIFactory
      .verfahrenResourceApiFactory()
      .wiederherstelleVerfahren(proceedingID)
      .then((response) => {
        resolve(response.data);
      })
      .catch(async (error) => {
        await appStore.showErrorModal({
          response: error,
          customErrorMessage: "Fehler beim Wiederherstellen des Verfahrens!",
        });
        reject(error);
      });
  });
}

// TODO move to fundamentalStore
/**
 * Method to update stored subscribed proceedings order
 * @param {Array<VerfahrenUebersichtRest>} orderedProceedingList  list of new ordered subscribed proceedings
 * @returns {void}
 */
export function reorderProceedings(orderedProceedingList = []) {
  const fundamentalStore = useFundamentalStore();
  const proceedingOrder = orderedProceedingList.map((item) => item?.planID);

  const cockpitSettings = {
    customProceedingOrder: proceedingOrder,
    sortingOrder: "CUSTOM",
  };

  updateUserSettings(cockpitSettings).then(() => {
    fundamentalStore.userSettings.cockpitSettings = cockpitSettings;
  });
}
