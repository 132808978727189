import type { ComputedRef } from "vue";
import { computed } from "vue";
import { useRouter } from "vue-router";

/**
 * Provides reactive access to the current planID from route params.
 * @returns { ComputedRef<string | undefined> } The current planID or undefined.
 */
export function usePlanID(): ComputedRef<string | undefined> {
  const router = useRouter();

  return computed(() => {
    const currentRoute = router.currentRoute.value;

    return currentRoute?.params?.planID ? currentRoute.params.planID.toString() : undefined;
  });
}
