import moment from "moment";
import { defineStore } from "pinia";

import type {
  BlacklistTerminRest,
  Codeliste,
  CodeVerfahrensschritt,
  CodeZustaendigkeit,
  NutzerRest,
  RechtsstandValidationRest,
  RechtszitatDetailRest,
  SystemParameterRest,
  VerfahrensKonfigurationRest,
  VerfahrensteilschrittKonfigurationRest,
  WhitelistTerminRest,
  WhitelistTerminSerieRest,
} from "@/services/open-api";
import { openAPIFactory } from "@/services/open-api.js";
import { useAppStore } from "@/stores/app.ts";

export type RawProceedingStructure = VerfahrensKonfigurationRest | undefined;

export interface CombinedProceedingStructure {
  [phaseCode: string]: {
    name?: string;
    phaseIndex: number;
    maxSteps: number;
    stepsBefore: number;
  } & { [key: string]: unknown };
}

interface VerfahrensschrittKonfiguration {
  name: string;
  verfahrensteilschritte?: {
    [key: string]: VerfahrensteilschrittKonfiguration;
  };
}

interface VerfahrensteilschrittKonfiguration extends VerfahrensteilschrittKonfigurationRest {
  name: string;
  codeVerfahrensschritt: CodeVerfahrensschritt;
  stepIndex: number;
}

export interface ProceedingStructure {
  name: string;
  verfahrensschritte?: {
    [key: string]: VerfahrensschrittKonfiguration;
  };
}

export interface CodeDescription {
  code?: string;
  name?: string;
  additional?: string;
  parent?: string;
  verfahrensSteuerungCode?: string;
}

export interface FundamentalStoreState {
  codelists: {
    [key: string]: CodeDescription[];
  };
  rawProceedingStructures: RawProceedingStructure[];
  proceedingStructures: {
    [key: string]: ProceedingStructure;
  };
  userSettings: {
    cockpitSettings: {
      customProceedingOrder: string[];
      sortingOrder?:
        | "AKTIV_SIMULIERT"
        | "SIMULIERT_AKTIV"
        | "START_DATUM"
        | "PLANNAME"
        | "VERFAHRENSSTAND"
        | "CUSTOM";
    };
    email?: string;
    nutzerID?: string;
    rechte: string[];
    rollen: string[];
    vollerName?: string;
    zustaendigkeit?: CodeZustaendigkeit;
  };
  systemParameters: SystemParameterRest[];
  showAllSystemParameters: boolean;
  rechtszitate: RechtszitatDetailRest[];
  rechtsstand: RechtsstandValidationRest;
  dateBlacklist: BlacklistTermin[];
  dateWhitelist: {
    [key: string]: WhitelistTerminRest[] | string;
  };
  whiteListData: WhitelistTerminRest[];
  whiteListSeriesData: WhitelistTerminSerieRest[];
}

interface BlacklistTermin extends BlacklistTerminRest {
  from?: string;
  to?: string;
  title?: string;
}

export const useFundamentalStore = defineStore("fundamental", {
  state: (): FundamentalStoreState => ({
    codelists: {},
    rawProceedingStructures: [],
    proceedingStructures: {},
    userSettings: {
      cockpitSettings: {
        customProceedingOrder: [],
        sortingOrder: undefined,
      },
      email: undefined,
      nutzerID: undefined,
      rechte: [],
      rollen: [],
      vollerName: undefined,
      zustaendigkeit: undefined,
    },
    systemParameters: [],
    showAllSystemParameters: false,
    rechtszitate: [],
    rechtsstand: {},
    dateBlacklist: [],
    dateWhitelist: {},
    whiteListData: [],
    whiteListSeriesData: [],
  }),
  actions: {
    /**
     * Loads and caches XBau codelists.
     * @param {boolean} forceReload Ignore cache and reload codelists.
     */
    loadConfigurationCodelists(forceReload = false): Promise<false | Codeliste[]> {
      const appStore = useAppStore();

      return new Promise((resolve) => {
        if (!appStore.resolved["/konfigurationen/codelisten"] || forceReload) {
          openAPIFactory
            .codelistResourceApiFactory()
            .getAllCodelisten()
            .then((response) => {
              this.codelists = {};

              response.data.forEach((codelist) => {
                if (codelist.name && codelist.enumeration) {
                  const codeListName = codelist.name.substring(5).toLowerCase();

                  this.codelists[codeListName] = codelist.enumeration.reduce(
                    (acc, cur) => {
                      const attribute = cur.attributes.attribute || [];
                      const additional =
                        attribute.find((x) => x.name === "wertZweiteBeschreibungsspalte")?.value ||
                        "";
                      const parent =
                        attribute.find((x) => x.name === "uebergeordneterWert")?.value || "";
                      const verfahrensSteuerungCode =
                        attribute.find((x) => x.name === "verfahrenssteuerung")?.value || "";

                      acc.push({
                        code: cur.code,
                        name: cur.name,
                        additional,
                        parent,
                        verfahrensSteuerungCode,
                      });
                      return acc;
                    },
                    <CodeDescription[]>[],
                  );
                }
              });

              appStore.resolved["/konfigurationen/codelisten"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Abfrage der Codelisten-Konfiguration fehlgeschlagen!",
              });
            });
        } else {
          resolve(false);
        }
      });
    },
    /**
     * Loads and caches basic proceeding structures.
     */
    loadProceedingStructures(forceReload = false): Promise<false | VerfahrensKonfigurationRest[]> {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        if (!appStore.resolved["/konfigurationen/verfahren"] || forceReload) {
          openAPIFactory
            .verfahrensKonfigurationRessourceApiFactory()
            .getAll()
            .then((response) => {
              this.rawProceedingStructures = response.data;

              const proceedingStructures: {
                [key: string]: ProceedingStructure;
              } = {};

              response.data.forEach((proceedingtype: RawProceedingStructure) => {
                if (
                  proceedingtype?.verfahrenssteuerung?.code &&
                  proceedingtype?.verfahrenssteuerung?.name
                ) {
                  const vsObj: { [key: string]: VerfahrensschrittKonfiguration } = {};

                  if (proceedingtype?.verfahrensschritte) {
                    for (const vs of proceedingtype.verfahrensschritte) {
                      const vtsObj: { [key: string]: VerfahrensteilschrittKonfiguration } = {};
                      let stepIndex = 1;

                      if (vs.verfahrensteilschritte) {
                        for (const vts of vs.verfahrensteilschritte) {
                          if (
                            vts.codeVerfahrensteilschritt?.code &&
                            vts.codeVerfahrensteilschritt?.name &&
                            vs.codeVerfahrensschritt
                          ) {
                            vtsObj[vts.codeVerfahrensteilschritt.code] = {
                              name: vts.codeVerfahrensteilschritt.name,
                              codeVerfahrensschritt: vs.codeVerfahrensschritt,
                              stepIndex,
                              codeVerfahrensteilschritt: vts.codeVerfahrensteilschritt,
                              mindestdauer: vts.mindestdauer,
                              maxdauer: vts.maxdauer,
                              termine: vts.termine,
                              unterverfahrensteilschritte: vts.unterverfahrensteilschritte,
                              informationsID: vts.informationsID,
                            };

                            stepIndex++;
                          }
                        }
                      }

                      if (vs.codeVerfahrensschritt?.code && vs.codeVerfahrensschritt?.name) {
                        vsObj[vs.codeVerfahrensschritt.code] = {
                          name: vs.codeVerfahrensschritt.name,
                          verfahrensteilschritte:
                            Object.keys(vtsObj).length > 0 ? vtsObj : undefined,
                        };
                      }
                    }
                  }

                  proceedingStructures[proceedingtype.verfahrenssteuerung.code] = {
                    name: proceedingtype.verfahrenssteuerung.name,
                    verfahrensschritte: Object.keys(vsObj).length > 0 ? vsObj : undefined,
                  };
                }
              });

              this.proceedingStructures = proceedingStructures;

              appStore.resolved["/konfigurationen/verfahren"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage:
                  "Bereitstellung der Verfahrenskonfigurationen ist fehlgeschlagen!",
              });

              reject(error);
            });
        } else {
          resolve(false);
        }
      });
    },
    /**
     * Loads and caches user related information.
     */
    loadUserSettings(): Promise<false | NutzerRest> {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        if (!appStore.resolved["/nutzer"]) {
          openAPIFactory
            .nutzerResourceApiFactory()
            .getNutzer()
            .then((response) => {
              this.userSettings = {
                nutzerID: response.data.nutzerID,
                vollerName: response.data.vollerName,
                email: response.data.email,
                rechte: Array.from(response.data.rechte || []),
                rollen: Array.from(response.data.rollen || []),
                cockpitSettings: Object.assign(
                  {
                    customProceedingOrder: [],
                    sortingOrder: "AKTIV_SIMULIERT",
                  },
                  JSON.parse(
                    typeof response.data.cockpitSettings !== "undefined"
                      ? response.data.cockpitSettings
                        ? response.data.cockpitSettings
                        : "{}"
                      : "{}",
                  ),
                ),
                zustaendigkeit: response.data.zustaendigkeit,
              };

              appStore.resolved["/nutzer"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Nutzerdaten konnten nicht geladen werden!",
              });
              reject(error);
            });
        } else {
          resolve(false);
        }
      });
    },
    loadRechtsstand(planID: string, verfahrensstand?: string): Promise<RechtsstandValidationRest> {
      const appStore = useAppStore();

      return new Promise((resolve, reject) => {
        openAPIFactory
          .xplanManagerResourceApiFactory()
          .getPublishedXplanGmlRechtsstandValidation(planID, verfahrensstand)
          .then((response) => {
            this.rechtsstand = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Laden der Rechtsstände ist fehlgeschlagen!",
            });
            reject(error);
          });
      });
    },
    /**
     * Loads and caches available Rechtszitate.
     * @param reload Ignore cache and reload rechtszitate.
     */
    loadRechtszitate(reload = false): Promise<RechtszitatDetailRest[]> {
      const appStore = useAppStore();

      if (!reload && typeof this.rechtszitate !== "undefined") {
        return new Promise((resolve) => resolve(this.rechtszitate));
      }

      return new Promise((resolve, reject) => {
        openAPIFactory
          .rechtszitatResourceApiFactory()
          .getAllRechtszitat()
          .then((response) => {
            this.rechtszitate = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Abfrage der Rechtszitate fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Deletes a specific Rechtszitat.
     * @param name The name identifier of the parameter that will be deleted
     */
    deleteRechtszitat(name: string) {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .rechtszitatResourceApiFactory()
          .deleteRechtszitat(name)
          .then(() => {
            const indexToRemove = this.rechtszitate.findIndex((param) => param.name === name);

            if (indexToRemove !== -1) {
              this.rechtszitate.splice(indexToRemove, 1);
            }

            resolve(true);
          })
          .catch((error) => {
            const appStore = useAppStore();

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Beim Löschen des Rechtszitats ist ein Fehler aufgetreten!",
            });

            reject(error);
          });
      });
    },
    /**
     * Loads and caches available system parameters.
     */
    loadSystemParameters(): Promise<false | SystemParameterRest[]> {
      return new Promise((resolve, reject) => {
        const appStore = useAppStore();

        openAPIFactory
          .systemParameterResourceApiFactory()
          .getAllSystemparameter(this.showAllSystemParameters)
          .then((response) => {
            this.systemParameters = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Abfrage der Systemparameter fehlgeschlagen!",
            });

            reject(error);
          });
      });
    },
    /**
     * Gets the value of a single system parameter.
     * @param name Name of the SystemParameter
     * @param reload Ignore cache and reload SystemParameters.
     * @param required Show error modal if SystemParameter can't be found.
     */
    getSystemParameter(
      name: string,
      reload = false,
      required = true,
    ): Promise<undefined | SystemParameterRest> {
      let systemParameter = this.getSystemParameterByName(name);

      if (!reload && systemParameter !== undefined) {
        return new Promise((resolve) => resolve(systemParameter));
      }

      return new Promise((resolve, reject) => {
        const appStore = useAppStore();

        openAPIFactory
          .systemParameterResourceApiFactory()
          .getSystemParameter(name)
          .then((response) => {
            this.updateSystemParameter(response.data);
            resolve(response.data);
          })
          .catch((error) => {
            if (required) {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: `Abfrage des Systemparameters "${name}" fehlgeschlagen!`,
              });

              reject(error);
            } else {
              resolve(undefined);
            }
          });
      });
    },
    /**
     * Removes a specific system parameter.
     * @param parameterName The parameter name.
     */
    removeSystemParameter(parameterName: string) {
      const systemParameters = [...this.systemParameters];

      this.systemParameters = systemParameters.filter((param) => param.name !== parameterName);
    },
    /**
     * Loads and caches the date blacklist for datepickers.
     */
    loadDateBlacklist(): Promise<false | BlacklistTerminRest[]> {
      return new Promise((resolve, reject) => {
        const appStore = useAppStore();

        if (!appStore.resolved["/konfigurationen/blacklist"]) {
          openAPIFactory
            .blacklistResourceApiFactory()
            .getBlacklist()
            .then((response) => {
              const dateBlacklist = response.data as BlacklistTermin[];

              dateBlacklist.forEach((entry) => {
                entry.from = entry.beginn;
                entry.to = entry.ende;
                entry.beginn = moment(entry.beginn).format("DD.MM.YYYY");
                entry.ende = moment(entry.ende).format("DD.MM.YYYY");
                entry.title = entry.zusatz;
              });

              this.dateBlacklist = dateBlacklist;

              appStore.resolved["/konfigurationen/blacklist"] = true;

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage: "Die Abfrage der Datums-Blacklist ist fehlgeschlagen!",
              });

              reject(error);
            });
        } else {
          resolve(false);
        }
      });
    },
    /**
     * Loads and caches the date whitelist for datepickers.
     */
    loadDateWhitelist(): Promise<WhitelistTerminRest[]> {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .whitelistResourceApiFactory()
          .getWhitelist()
          .then((response) => {
            this.whiteListData = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Loads and caches date whitelist series for datepickers.
     */
    loadDateWhitelistSeries(): Promise<WhitelistTerminSerieRest[]> {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .whitelistResourceApiFactory()
          .getWhitelistSerie()
          .then((response) => {
            this.whiteListSeriesData = response.data;

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * Loads and caches the city district specific date whitelist.
     * @param zustaendigkeit The code of the city district to load the whitelist for.
     */
    loadDateWhitelistByZustaendigkeit(
      zustaendigkeit: string,
    ): Promise<false | WhitelistTerminRest[]> {
      return new Promise((resolve) => {
        const appStore = useAppStore();

        if (
          typeof this.dateWhitelist[zustaendigkeit] === "undefined" ||
          (this.dateWhitelist[zustaendigkeit] !== "loading" &&
            this.dateWhitelist[zustaendigkeit].length < 1)
        ) {
          this.dateWhitelist[zustaendigkeit] = "loading";

          openAPIFactory
            .whitelistResourceApiFactory()
            .getWhitelistByZustaendigkeit(zustaendigkeit)
            .then((response) => {
              this.dateWhitelist[zustaendigkeit] = [...response.data];

              resolve(response.data);
            })
            .catch((error) => {
              appStore.showErrorModal({
                response: error,
                customErrorMessage:
                  "Die Abfrage der Datums-Whitelist für den Bezirk " +
                  zustaendigkeit +
                  "ist fehlgeschlagen!",
              });
            });
        } else {
          resolve(false);
        }
      });
    },
    /**
     * Deletes a specific whitelist.
     */
    deleteWhiteListById(whitelistID: number): Promise<true> {
      return new Promise((resolve, reject) => {
        openAPIFactory
          .whitelistResourceApiFactory()
          .deleteWhitelist([whitelistID])
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            const appStore = useAppStore();

            appStore.showErrorModal({
              response: error,
              customErrorMessage: "Beim Löschen des Sitzungstermins ist ein Fehler aufgetreten!",
            });

            reject(error);
          });
      });
    },
    /**
     * Updates or creates a specific system parameter.
     * @param systemParameterData The parameter object to update.
     */
    updateSystemParameter(systemParameterData: SystemParameterRest) {
      const systemParameters = [...this.systemParameters];
      const parameterIndex = systemParameters.findIndex(
        (param) => param.name === systemParameterData.name,
      );

      // This mutation is used both for editing existing parameters as well as
      // inserting new ones. We need to distinct between these cases in order
      // to perform to correct action.
      if (parameterIndex > -1) {
        systemParameters.splice(parameterIndex, 1, systemParameterData);
      } else {
        systemParameters.push(systemParameterData);
      }

      this.systemParameters = systemParameters;
    },
  },
  getters: {
    /**
     * Returns the XBau codelist defined by its name.
     * @returns An array of codelist items.
     */
    getCodelistByName(): (name: string) => CodeDescription[] {
      return (name) => {
        return this.codelists && this.codelists[name.toLowerCase()]
          ? this.codelists[name.toLowerCase()]
          : [];
      };
    },
    /**
     * returns the code of a verfahrensstand
     */
    getVerfahrensstandCodeByName(): (name: string) => string | undefined {
      const verfahrensstandCodelist = this.getCodelistByName("verfahrensstand");

      return (name: string) =>
        verfahrensstandCodelist.find((verfahrensstand) => verfahrensstand.name === name)?.code;
    },
    /**
     * Returns a specific system parameter.
     * @returns The value of the system parameter.
     */
    getSystemParameterByName(): (name: string) => SystemParameterRest | undefined {
      return (name) => {
        return this.systemParameters
          ? this.systemParameters.find(
              (parameter: SystemParameterRest) =>
                parameter.name?.toLowerCase() === name.toLowerCase(),
            )
          : undefined;
      };
    },
    /**
     * Returns a Boolean or String containing the configuration of the detail page component depending on the proceeding type.
     * @returns The result of the check.
     */
    checkDetailSectionVisibility(): (name: string) => true | string {
      return (name) => {
        const displaySetupParameterName = "DISPLAY_SETUP_STAMMDATEN_PAGE";
        const systemParameterData = this.getSystemParameterByName(displaySetupParameterName);
        const displayContentProceedingType = this.getSystemParameterByName(
          displaySetupParameterName,
        )
          ? systemParameterData?.wert
            ? JSON.parse(systemParameterData.wert)
            : undefined
          : undefined;

        if (!displayContentProceedingType) {
          return true;
        }

        return name
          .split("/")
          .reduce(
            (acc, curr) =>
              Object.prototype.toString.call(acc) !== "[object Object]" || acc[curr] === undefined
                ? true
                : acc[curr],
            displayContentProceedingType,
          );
      };
    },
    /**
     * Returns a boolean indicating if a user has a given permission.
     * @returns The result of the check.
     */
    checkUserPermission(): (permissionID: string) => boolean {
      return (permissionID) => {
        return this.userSettings
          ? this.userSettings.rechte
              .map((permissionID) => permissionID.toLowerCase())
              .indexOf(permissionID.toLowerCase()) > -1
          : false;
      };
    },
    /**
     * Returns a datumsstatus object ordered by name
     * @returns Object containing code and name of datumsstatus code list
     */
    datumsStatusByName(): { [key: string]: string } {
      const datumsstatus = this.getCodelistByName("datumsstatus");
      const datumsStatusObj: { [key: string]: string } = {};

      datumsstatus.forEach((status: CodeDescription) => {
        if (status.name && status.code) {
          datumsStatusObj[status.name] = status.code;
        }
      });

      return datumsStatusObj;
    },
    /**
     * Returns an array of Verfahrenssteuerung types including the existing verfahrenssteuerung from 'proceedingStructures'
     */
    verfahrenssteuerungTypes(): CodeDescription[] {
      return Object.keys(this.proceedingStructures).reduce(
        (accumulated, currentKey) => {
          return [
            ...accumulated,
            {
              code: currentKey,
              name: this.proceedingStructures[currentKey].name,
            },
          ];
        },
        <CodeDescription[]>[],
      );
    },
    /**
     * Returns an array of Verfahrensschritte based on verfahrenssteuerungstyp passed.
     */
    getVerfahrensschritte(): (verfahrenssteuerungsTyp: CodeDescription) => CodeDescription[] {
      return (verfahrenssteuerungsTyp) => {
        if (verfahrenssteuerungsTyp.code) {
          const stepDefinitions =
            this.proceedingStructures[verfahrenssteuerungsTyp.code]?.verfahrensschritte;

          return stepDefinitions
            ? Object.keys(stepDefinitions).reduce(
                (accumulated, currentKey) => {
                  return [
                    ...accumulated,
                    {
                      code: currentKey,
                      name: stepDefinitions[currentKey].name,
                    },
                  ];
                },
                <CodeDescription[]>[],
              )
            : [];
        }

        return [];
      };
    },
    /**
     * Returns an array of Verfahrensteilschritte based on verfahrenssteuerungsTyp and verfahrensschritt passed.
     */
    getVerfahrensteilschritte(): (
      verfahrenssteuerungsTyp: CodeDescription,
      verfahrensschritt: CodeDescription,
    ) => CodeDescription[] {
      return (verfahrenssteuerungsTyp, verfahrensschritt) => {
        if (verfahrenssteuerungsTyp.code && verfahrensschritt.code) {
          const stepDefinitions =
            this.proceedingStructures[verfahrenssteuerungsTyp.code]?.verfahrensschritte;

          if (stepDefinitions) {
            const subStepDefinitions =
              stepDefinitions[verfahrensschritt.code]?.verfahrensteilschritte;

            return subStepDefinitions
              ? Object.keys(subStepDefinitions).reduce(
                  (accumulated, currentKey) => {
                    return [
                      ...accumulated,
                      {
                        code: currentKey,
                        name: subStepDefinitions[currentKey].name,
                      },
                    ];
                  },
                  <CodeDescription[]>[],
                )
              : [];
          }

          return [];
        }

        return [];
      };
    },
    //TODO: Check if this is still needed after moving function to proceeding store
    /**
     * Returns a function that determines a dynamically computed proceeding structure array for given proceeding types.
     */
    getProceedingStructuresForProceedingTypes(): (
      proceedingTypes: string | string[],
    ) => CombinedProceedingStructure {
      return (proceedingTypes) => {
        const proceedingTypeCodes = !Array.isArray(proceedingTypes)
            ? [proceedingTypes]
            : proceedingTypes,
          proceedingPhaseOrder = this.getCodelistByName("verfahrensschritte"),
          proceedingStructures = this.proceedingStructures,
          combinedProceedingStructures: CombinedProceedingStructure = {};

        // Make sure that the enumeration of the phase codelist is actually sorted as this codelist determines
        // the order of the proceeding phases displayed.
        proceedingPhaseOrder.sort((a, b) => {
          return a.code && b.code && a.code < b.code ? -1 : 1;
        });

        // Create a sequence on included phases, starting at 1
        let phaseIndex = 1;

        // Process each available proceeding phase separately.
        proceedingPhaseOrder.forEach((proceedingPhase) => {
          // Process each displayed proceeding type one by one.
          proceedingTypeCodes.forEach((proceedingTypeCode) => {
            // Check if the currently processed proceeding type features the current proceeding phase
            if (
              proceedingPhase.code &&
              proceedingStructures[proceedingTypeCode]?.verfahrensschritte &&
              Object.keys(
                proceedingStructures[proceedingTypeCode].verfahrensschritte || {},
              ).indexOf(proceedingPhase.code) > -1
            ) {
              const proceedingStructureBranch = {
                ...proceedingStructures[proceedingTypeCode].verfahrensschritte,
              };
              const proceedingPhaseStepCount = Object.values(
                proceedingStructureBranch[proceedingPhase.code]?.verfahrensteilschritte || {},
              ).length;

              // The first proceeding type to feature a given proceeding phase has to initialize the phase container.
              if (typeof combinedProceedingStructures[proceedingPhase.code] === "undefined") {
                combinedProceedingStructures[proceedingPhase.code] = {
                  name: proceedingPhase.name,
                  phaseIndex,
                  maxSteps: 0,
                  stepsBefore: 0,
                };

                phaseIndex++;
              }

              // Now extract all phase sub steps of the current proceeding phase
              if (proceedingStructureBranch[proceedingPhase.code].verfahrensteilschritte) {
                combinedProceedingStructures[proceedingPhase.code][proceedingTypeCode] =
                  proceedingStructureBranch[proceedingPhase.code].verfahrensteilschritte;
              }

              // Keep track of the maximum number or sub steps.
              if (
                combinedProceedingStructures[proceedingPhase.code].maxSteps <
                proceedingPhaseStepCount
              ) {
                combinedProceedingStructures[proceedingPhase.code].maxSteps =
                  proceedingPhaseStepCount;
              }
            }
          });
        });

        let stepsBefore = 0;

        Object.values(combinedProceedingStructures).forEach((proceedingPhase) => {
          proceedingPhase.stepsBefore = stepsBefore;
          stepsBefore += proceedingPhase.maxSteps;
        });

        return combinedProceedingStructures;
      };
    },
    /**
     * function to determine if current user has pro user rights
     */
    isProUser(): boolean {
      return (
        this.checkUserPermission("VERFAHRENSSTEUERUNG_READ") ||
        this.checkUserPermission("VERFAHRENSSTEUERUNG_WRITE")
      );
    },
  },
});
