import { usePlanID } from "@/composables/usePlanID.ts";

export const currentPlanID = {
  computed: {
    planID() {
      const planID = usePlanID();

      return planID.value;
    },
  },
};
